import { Card, Col, Form, FormProps, Row, Tag } from 'antd';
import { FC, useEffect, useState } from 'react';
import EndPoints from '../../shared/endPoints.json';
import BaseTable from '../../components/BaseTable/BaseTable';
import { dateEpochToGR } from '../../shared/services/helperFunctions';
import { baseStyle } from '../../shared/styles/baseStyle';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../../shared/services/apiServices';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AddUser from './AddUser';
const UsersCatalogue: FC = () => {
  const { request } = useCreateAxiosInstance();
  const [form] = Form.useForm();
  const defaultCurrentPage = 1;
  const pageSize = 6;
  const defaultUrl = `${EndPoints.USER.USER}?Items=${pageSize}&Page=${defaultCurrentPage}`;
  const [url, setUrl] = useState(defaultUrl);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const columns = [
    {
      title: 'Όνομα',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Επίθετο',
      dataIndex: 'lastName',
      key: 'lastName',
    },

    {
      title: 'Username (e-mail)',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'ΑΦΜ',
      dataIndex: 'vatNumber',
      key: 'vatNumber',
    },
    {
      title: 'Τηλέφωνο',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Οδός',
      dataIndex: 'addressName',
      key: 'addressName',
    },
    {
      title: 'Αριθμός',
      dataIndex: 'addressNumber',
      key: 'addressNumber',
    },
    {
      title: 'Περιοχή',
      dataIndex: 'district',
      key: 'district',
    },
    {
      title: 'Δήμος',
      dataIndex: 'municipality',
      key: 'municipality',
    },
    {
      title: 'Τ.Κ',
      dataIndex: 'postalCode',
      key: 'postalCode',
    },
    {
      title: 'Επιλογή Πακέτου',
      dataIndex: 'subscriptionPackage',
      key: 'subscriptionPackage',
    },
    {
      title: 'Έναρξη Συνδρομής',
      dataIndex: 'subscriptionDateStarted',
      key: 'subscriptionDateStarted',
      render: (epochTime: string) => dateEpochToGR(+epochTime),
    },
    {
      title: 'Προέλευση ',
      dataIndex: 'userOrigin',
      key: 'userOrigin',
    },
    {
      title: 'Ρόλος',
      dataIndex: 'role',
      key: 'role',
      render: (role: string) => {
        return (
          <Tag color={role == 'Καλλιεργητής' ? 'green' : 'geekblue'}>
            {role}
          </Tag>
        );
      },
    },
    {
      title: 'Σημειώσεις',
      dataIndex: 'notes',
      key: 'notes',
    },
  ];
  const { data: users, isLoading } = useQuery({
    queryKey: ['users', url],
    queryFn: () => getData(request, url),
    select(data) {
      return data.data;
    },
  });

  useEffect(() => {
    setUrl(`${defaultUrl}&${searchParams}`);
  }, [searchParams]);

  const onChange = (pagination: any) => {
    setUrl(
      `${EndPoints.USER.USER}?Items=${pageSize}&Page=${pagination.current}&${searchParams}`,
    );
  };
  const onFinish: FormProps['onFinish'] = async (formValues: any) => {
    const values = {
      ...formValues,
    };
    Object.keys(values).forEach((key) => !values[key] && delete values[key]);
    const params = new URLSearchParams(values);
    navigate(`/users/catalogue?${params}`, { replace: true });
    setUrl(`${defaultUrl}&${params}`);
  };
  return (
    <Row align="middle" justify="center" style={{ ...baseStyle.mainRow }}>
      <Col span={22}>
        <AddUser
          showFieldOnFilter={false}
          title={'Κριτήρια Αναζήτησης'}
          button={'Αναζήτηση'}
          onFinishFunction={onFinish}
        />
        <Card hoverable>
          <BaseTable
            dataSource={users?.items}
            columns={columns}
            loading={isLoading}
            paginationConfig={{
              pageSize: pageSize,
              total: users?.totalCount,
            }}
            onChange={onChange}
            rowSelection={null}
          />
        </Card>
      </Col>
    </Row>
  );
};
export default UsersCatalogue;
