import {
  Col,
  Row,
  Button,
  Form,
  type FormProps,
  Input,
  Card,
  Typography,
  Grid,
  Checkbox,
  Modal,
} from 'antd';
import { FC, useContext, useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {
  card,
  title,
  signIn,
  registerButton,
  formStyle,
  cardRow,
  registerBackgroundColor,
  signUpRow,
  whiteText,
  registerButtonRow,
  forgotPasswordbutton,
  checkboxRow,
  checkboxLink,
} from './style';
import { outerRow } from '../../shared/styles/baseStyle';
import { fullWidth } from '../../shared/styles/baseStyle';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../shared/context/auth-context';
import LoadingSpin from '../../components/LoadingSpin/LoadingSpin';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { useMutation } from '@tanstack/react-query';
import { mutateData } from '../../shared/services/apiServices';
import endPoints from '../../shared/endPoints.json';

type FieldType = {
  email: string;
  username: string;
  password: string;
};
const Login: FC = () => {
  const [checkAuthentication, setCheckAuthentication] = useState(false);
  const [checkPrivacyPolicy, setCheckPrivacyPolicy] = useState(false);
  const [checkTermsOfUse, setCheckTermsOfUse] = useState(false);
  const [isModalOpenAuthentication, setIsModalOpenAuthentication] =
    useState(false);
  const [isModalOpenPrivacyPolicy, setIsModalOpenPrivacyPolicy] =
    useState(false);
  const [isModalOpenTermsOfUse, setIsModalOpenTermsOfUse] = useState(false);
  const authenticationTitle = 'Δήλωση Αυθεντικοποίησης';
  const privacyPolicyTitle = 'Πολιτική Απορρήτου';
  const termsOfUseTitle = 'Όροι Χρήσης';
  const authenticationStatement =
    'Τα στοιχεία που υποβάλλονται στην παρούσα πλατφόρμα (https://www.agros-online.gr) είναι ακριβή και αληθή. Δεσμεύομαι να συμμορφώνομαι με την σχετική Ελληνική νομοθεσία και με τους εφαρμοστέους κανόνες του δικαίου της Ένωσης.';
  const privacyPolicyStatement = 'privacyPolicyStatement';
  const termsOfUseStatement = 'termsOfUseStatement';
  const { login } = useContext(AuthContext);
  const { request } = useCreateAxiosInstance();
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const { Title, Text } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useMutation({
    mutationFn: ({ email, password }: any) =>
      mutateData(
        request,
        endPoints.USER.LOGIN,
        { username: email, password },
        'post',
      ),
    onSuccess(data) {
      login(data.data);
    },
    onSettled() {
      setIsLoading(false);
    },
  });
  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    setIsLoading(true);
    mutate({ email: values.email, password: values.password });
  };

  if (isLoading) return <LoadingSpin />;
  return (
    <Row align="middle" style={outerRow}>
      <Row justify="center" style={fullWidth}>
        <Col xs={20} sm={16} md={12} lg={8} xxl={6}>
          <Card style={card}>
            <Title level={2} style={title}></Title>
            <Text style={signIn}>Συνδεθείτε στο λογαριασμό σας</Text>
            {!breakpoints.lg && (
              <>
                <Text>{` ή`}</Text>
                <Button
                  type="link"
                  style={registerButton}
                  onClick={() => {
                    navigate('/register');
                  }}
                >
                  Εγγραφή Τώρα!
                </Button>
              </>
            )}
            <Form size="large" onFinish={onFinish} style={formStyle}>
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Εισάγετε το email σας!' }]}
              >
                <Input prefix={<UserOutlined />} placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Εισάγετε τον κωδικό πρόσβασής σας!',
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Κωδικός Πρόσβασης"
                />
              </Form.Item>
              <Row style={checkboxRow}>
                <Checkbox
                  onChange={(e) => {
                    setCheckTermsOfUse(e.target.checked);
                  }}
                >
                  <Button
                    type="link"
                    onClick={() => {
                      setIsModalOpenTermsOfUse(true);
                    }}
                    style={checkboxLink}
                  >
                    {termsOfUseTitle}
                  </Button>
                </Checkbox>
                <Modal
                  title={termsOfUseTitle}
                  footer={null}
                  open={isModalOpenTermsOfUse}
                  onCancel={() => {
                    setIsModalOpenTermsOfUse(false);
                  }}
                >
                  {termsOfUseStatement}
                </Modal>
              </Row>
              <Row style={checkboxRow}>
                <Checkbox
                  onChange={(e) => {
                    setCheckPrivacyPolicy(e.target.checked);
                  }}
                >
                  <Button
                    type="link"
                    onClick={() => {
                      setIsModalOpenPrivacyPolicy(true);
                    }}
                    style={checkboxLink}
                  >
                    {privacyPolicyTitle}
                  </Button>
                </Checkbox>
                <Modal
                  title={privacyPolicyTitle}
                  footer={null}
                  open={isModalOpenPrivacyPolicy}
                  onCancel={() => {
                    setIsModalOpenPrivacyPolicy(false);
                  }}
                >
                  {privacyPolicyStatement}
                </Modal>
              </Row>
              <Row style={checkboxRow}>
                <Checkbox
                  onChange={(e) => {
                    setCheckAuthentication(e.target.checked);
                  }}
                >
                  <Button
                    type="link"
                    onClick={() => {
                      setIsModalOpenAuthentication(true);
                    }}
                    style={checkboxLink}
                  >
                    {authenticationTitle}
                  </Button>
                </Checkbox>
                <Modal
                  title={authenticationTitle}
                  footer={null}
                  open={isModalOpenAuthentication}
                  onCancel={() => {
                    setIsModalOpenAuthentication(false);
                  }}
                >
                  {authenticationStatement}
                </Modal>
              </Row>
              <Row style={cardRow}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !(
                      checkAuthentication &&
                      checkPrivacyPolicy &&
                      checkTermsOfUse
                    )
                  }
                >
                  Σύνδεση
                </Button>
                <Button
                  type="link"
                  style={forgotPasswordbutton}
                  onClick={() => {
                    navigate('/forgotPassword');
                  }}
                >
                  Ξεχάσατε τον κωδικό;
                </Button>
              </Row>
            </Form>
          </Card>
        </Col>
        {breakpoints.lg && (
          <Col xs={12} sm={20} lg={8} xl={6} style={registerBackgroundColor}>
            <Row justify="center" style={signUpRow}>
              <Title level={2} style={whiteText}>
                Εγγραφή
              </Title>
            </Row>
            <Row justify="center">
              <Text style={whiteText}>
                Δεν έχετε λογαριασμό; Συμπληρώστε τη
              </Text>
            </Row>
            <Row justify="center">
              <Text style={whiteText}>φόρμα για να εγγραφείτε</Text>
            </Row>
            <Row justify="center" style={registerButtonRow}>
              <Button
                onClick={() => {
                  navigate('/register');
                }}
              >
                Εγγραφή Τώρα!
              </Button>
            </Row>
          </Col>
        )}
      </Row>
    </Row>
  );
};
export default Login;
